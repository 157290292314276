.image {
  width: auto !important;
  height: auto !important;
  min-width: 100%;
  max-width: 100% !important;
  max-height: none !important;
  background-color: #e5e7eb;
}

.content {
  position: relative;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: auto;
  transition: position 10ms ease;
}

.wrapper > span {
  width: 100% !important;
  height: 100% !important;
}
